import React, { Component } from 'react'
import classNames from 'classnames'
import Logo from '../svg/Logo'
import MenuButton from '../MenuButton'
import styles from './styles.module.css'

const cx = classNames.bind(styles)

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleMenu = () => {
    this.setState(prev => ({
      expanded: !prev.expanded
    }));
  }

  // necessary to account for switching between wide/narrow sizes
  closeMenu = () => {
    this.setState({
      expanded: false
    });
  }

  render() {
    const { expanded } = this.state;
    return (
      <header className={cx(styles.container, {[styles.expanded]: expanded})}>
        <div className={styles.pageContent}>
          <div className={styles.left}>
            <Logo className={styles.logo}/>
            <div className={styles.name}>
              <h1 className={styles.massbridge}>massbridge</h1>
              <h1 className={styles.software}>software</h1>
            </div>
          </div>
          <nav className={styles.right}>
            <ul>
              <li className={styles.link}>
                <a href='#services' onClick={this.closeMenu}>
                  services
                </a>
              </li>
              <li className={styles.link}>
                <a href='#about' onClick={this.closeMenu}>
                  about
                </a>
              </li>
              <li className={styles.link}>
                <a href='#clients' onClick={this.closeMenu}>
                  clients
                </a>
              </li>
              <li className={styles.link}>
                <a href='#contact' onClick={this.closeMenu}>
                  contact
                </a>
              </li>
            </ul>
          </nav>
          <MenuButton onClick={this.toggleMenu} className={styles.hamburger} />
          { expanded &&
            <span
              dangerouslySetInnerHTML={{
                __html: `<style>
                  :root body {
                    height: 100vh;
                    overflow-y: hidden;
                  }
                 </style>`
              }} />
          }
        </div>
      </header>
    )
  }
}

export default Header
