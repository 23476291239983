import React from 'react'
import Hamburger from './svg/Hamburger'

const MenuButton = ({ onClick, ...other }) => {
  return (
    <div onClick={onClick} {...other}>
      <Hamburger/>
    </div>
  )
}

export default MenuButton