import React from 'react'

const Logo = props => (
  <svg width="41px" height="47px" viewBox="0 0 41 47" {...props}>
    <g
      transform="translate(-39 -32) translate(39 27) translate(0 5.367)"
      fill="#FFF"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    >
      <path d="M30.5527841 28.8712842L33.2707841 35.6172842 40.3377841 33.0522842 33.8297841 24.6302842z" />
      <path d="M21.1738841 40.6720842L35.2078841 45.7660842 27.1468841 25.7520842z" />
      <path d="M8.56058414 36.6681842L8.44158414 36.9731842 8.39058414 37.0891842 4.91958414 45.7671842 19.0455841 40.6401842 19.2115841 10.0451842z" />
      <path d="M12.1064841 22.4435842L10.8294841 19.2725842 0.180484138 33.0515842 6.88448414 35.4855842z" />
      <path d="M9.96488414 17.1280842L7.24788414 10.3830842 0.180884138 12.9480842 6.68788414 21.3690842z" />
      <path d="M29.6894841 26.7267842L40.3374841 12.9487842 33.2944841 10.3917842 28.2174841 23.0757842z" />
      <path d="M35.2109841 0.232584209L21.2319841 5.30658421 21.0669841 35.5855842z" />
      <path d="M13.1777841 19.7657842L19.0057841 5.20478421 5.30978414 0.234784209z" />
    </g>
  </svg>
);

export default Logo
