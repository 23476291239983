import React from "react";

const Hamburger = props => (
  <svg width="40px" height="32px" viewBox="0 0 40 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>menu</title>
    <desc>Created with Sketch.</desc>
    <g id="massbridge" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="mobile" transform="translate(-255.000000, -28.000000)" fill="#FFFFFF">
        <g id="nav" transform="translate(236.000000, 9.000000)">
          <g id="menu" transform="translate(19.000000, 19.000000)">
            <polygon id="bar_bottom" transform="translate(20.000000, 29.000000) scale(-1, 1) translate(-20.000000, -29.000000) " points="-7.10542736e-14 26 39.9922033 26.52524 40 32 0.00779670193 32"></polygon>
            <polygon id="bar_middle" transform="translate(20.000000, 16.000000) scale(-1, 1) translate(-20.000000, -16.000000) " points="-7.10542736e-14 13 39.9922033 13 40 19 0.00779670193 19"></polygon>
            <polygon id="bar_top" transform="translate(20.000000, 3.000000) scale(-1, 1) translate(-20.000000, -3.000000) " points="-7.10542736e-14 -3.77475828e-14 39.9922033 -3.77475828e-14 40 6 0.00779670193 5.49678566"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Hamburger;
