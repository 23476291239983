import React from "react";

const UpTriangles = props => (
  <svg width="29px" height="16px" viewBox="0 0 29 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>arrow</title>
    <desc>Created with Sketch.</desc>
    <g id="massbridge" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="mobile" transform="translate(-25.000000, -4394.000000)" fill="#E32C68">
        <g id="Group" transform="translate(24.000000, 4393.000000)">
          <g id="btn_top" transform="translate(0.671331, 0.665268)">
            <g id="arrow" transform="translate(0.506361, 0.112963)">
              <polygon id="Fill-61" transform="translate(21.340927, 7.948619) rotate(179.000000) translate(-21.340927, -7.948619) " points="27.8736163 15.1353323 28.0787832 0.761904762 14.60307 1.00701589"></polygon>
              <polygon id="Fill-61-Copy" transform="translate(7.499761, 7.948619) scale(-1, 1) rotate(179.000000) translate(-7.499761, -7.948619) " points="14.0324511 15.1353323 14.237618 0.761904762 0.761904762 1.00701589"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UpTriangles;
