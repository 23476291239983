import React from 'react'
import styles from './styles.module.css'
import Logo from '../svg/Logo'
import UpTriangles from '../svg/UpTriangles'

const Footer = () => (
  <footer className={styles.container}>
    <div className={styles.pageContent}>
      <nav className={styles.left}>
        <ul>
          <li className={styles.link}>
            <a href='#'>
              <UpTriangles />
              top
            </a>
          </li>
          <li className={styles.link}>
            <a href='#about'>
              about
            </a>
          </li>
          <li className={styles.link}>
            <a href='#services'>
              services
            </a>
          </li>
          <li className={styles.link}>
            <a href='#clients'>
              clients
            </a>
          </li>
        </ul>
      </nav>
      <div className={styles.right}>
        <p>(919) 213-0660</p>
        <p>info@massbridge.co</p>
        <div className={styles.company}>
          <div>
            <p>massbridge</p>
            <p>software © 2019</p>
          </div>
          <Logo height={35} width={30} />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
